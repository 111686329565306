import Vue from 'vue'
import Router from 'vue-router'
import { AUTH_TOKEN, onLogout } from '@/vue-apollo'
import { store } from '@/store'
import { apolloClient } from '@/main'

import { RoleName } from '@/lib/interfaces/RoleName.enum'

import Me from '@/graphql/queries/Me.gql'
import { IMeData } from '@/graphql/queries/interfaces/Me.interface'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/layouts/Default.vue'),
      redirect: { name: 'register-company' },
      children: [
        {
          path: '/register-company',
          name: 'register-company',
          component: () => import('@/views/RegisterCompany.vue'),
        },
        {
          path: '/about',
          name: 'about',
          component: () => import('@/views/About.vue'),
        },
        {
          path: '/drivers',
          name: 'drivers',
          component: () => import('@/views/Drivers.vue'),
          meta: {
            authorizedTo: [RoleName.Company]
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/views/Profile.vue'),
          meta: {
            authorizedTo: [RoleName.Company]
          }
        },
        {
          path: '/companies',
          name: 'companies',
          component: () => import('@/views/Companies.vue'),
          meta: {
            authorizedTo: [RoleName.Organizer]
          }
        },
        {
          path: '/invoice',
          name: 'invoice',
          component: () => import('@/views/Invoice.vue'),
          meta: {
            authorizedTo: [RoleName.Organizer]
          }
        },
        {
          name: 'deleteAccount',
          path: '/delete-account',
          component: () => import('@/views/DeleteAccount.vue')
        },
        {
          name: 'requestDeletion',
          path: '/request-deletion',
          component: () => import('@/views/RequestDeletion.vue')
        },
        {
          name: 'requestPasswordReset',
          path: '/reset-password-request',
          component: () => import('@/views/RequestPasswordReset.vue')
        },
        {
          name: 'resetPassword',
          path: '/reset-password',
          component: () => import('@/views/ResetPassword.vue'),
        },
        {
          name: 'resetPassword',
          path: '/passwordReset',
          component: () => import('@/views/ResetPassword.vue'),
        },
        {
          name: 'registerPassword',
          path: '/register',
          component: () => import('@/views/RegisterDriver.vue')
        },
        {
          name: 'driverRegistrationComplete',
          path: '/registration-complete',
          component: () => import('@/views/RegisterDriverComplete.vue')
        },
        {
          name: 'legal-agb',
          path: '/legal/agb',
          redirect: { name: 'about' },
        },
        {
          name: 'legal-imprint',
          path: '/legal/imprint',
          component: () => import('@/views/legal/Imprint.vue')
        },
        {
          name: 'legal-privacy',
          path: '/legal/privacy',
          component: () => import('@/views/legal/Privacy.vue')
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/Login.vue')
        }, 
      ]
    },
    {
      path: '/faq-company',
      component: () => import('@/layouts/Default.vue'),
      children: [
        {
          path: '',
          component: () => import('@/views/Faq.vue'),
        }, 
      ]
    },
    {
      path: '/faq-:type',
      component: () => import('@/layouts/Blank.vue'),
      children: [
        {
          path: '',
          name: 'faq',
          component: () => import('@/views/Faq.vue'),
          meta: {
            'X-Robots-Tag' : "noindex"
          },
        }, 
      ]
    },
    {
      path: '*',
      redirect: { name: 'register-company' }
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  if(to.name === 'login') {
    next()
    return
  }
  // Assert correct user data and token
  const hasToken = localStorage.getItem(AUTH_TOKEN)
  let me = store.state.me
  if (!hasToken) {
    if (me) {
      store.commit('me', undefined)
    }
  } else if (!me) {
    try {
      const { data } = await apolloClient.query<IMeData>({ query: Me })
      store.commit('me', data.viewer.user)
      me = data.viewer.user
    } catch (err) {
      await onLogout(apolloClient)
      return next('login')
    }
  }

  // Redirect on /login if user is logged in
  if (me != null && to.name === 'login') {
    if (me.type === RoleName.Company) {
      next('drivers')
    }
    if (me.type === RoleName.Organizer) {
      next('companies')
    }
  }

  // Pass routes where no authorization is needed
  if (!to.meta!.authorizedTo || to.meta!.authorizedTo.length === 0) {
    return next()
  }

  // Check authorization on locked routes
  const hasNeededRole = me && (to.meta!.authorizedTo as string[]).includes(me.type)
  if (!hasNeededRole) {
    return next('login')
  }
  next()
})

export default router
